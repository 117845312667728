import React from 'react'
import styled from 'styled-components'

const ArticleHeroWrapper = styled.header`
  padding: 128px 32px 96px;
`
const ArticleHeroTitle = styled.h1`
  font-size: 38px;
  text-align: center;
  letter-spacing: 1px;
`

export interface ArticleHeroProps {
  title: string
}

const ArticleHero: React.FC<ArticleHeroProps> = ({ title }) => {
  return (
    <ArticleHeroWrapper>
      <ArticleHeroTitle>{title}</ArticleHeroTitle>
    </ArticleHeroWrapper>
  )
}

export default ArticleHero
