import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { MDXProvider } from '@mdx-js/react'

import MaxWidthWrapper from '../../components/MaxWidthWrapper/MaxWidthWrapper'
import StandardBlogPost from '../StandardBlogPost'
import ArticleHero from './ArticleHero'
import CodeBlock from '../../components/CodeBlock/CodeBlock'

const PostContentWrapper = styled.article`
  max-width: 675px;
  margin: auto;
`

const components = {
  pre: props => <CodeBlock code={props.children} {...props} />,
}

export interface SinglePostProps {
  data: any //TODO types from data
}

const SinglePost: React.FC<SinglePostProps> = ({ data }) => {
  return (
    <StandardBlogPost>
      <ArticleHero title={data.mdx.frontmatter.title} />
      <MaxWidthWrapper>
        <PostContentWrapper>
          <MDXProvider components={components}>
            <MDXRenderer>{data.mdx.body}</MDXRenderer>
          </MDXProvider>
        </PostContentWrapper>
      </MaxWidthWrapper>
    </StandardBlogPost>
  )
}

export const postQuery = graphql`
  query SinglePostQuery($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        date
        excerpt
        slug
        title
      }
    }
  }
`

export default SinglePost
